import { sgqDocumentIcon } from '@/assets/images/svg/sgqDocument'
import {
  mdiAccountCircle,
  mdiAccountGroupOutline,
  mdiFileDocument,
  mdiNewspaper,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

const userProfile = localStorageSlim.get('userProfile', { decrypt: true })

const Sgq = [
  {
    subheader: 'SGQ',
  },
  {
    title: 'RIC',
    icon: mdiAccountCircle,
    children: [
      {
        title: 'Registrar',
        to: 'sgq-home',
        hidden: false,
      },
    ],
  },
  {
    title: 'RI`s',
    icon: mdiNewspaper,
    children: [
      {
        title: 'Registrar',
        to: 'sgq-internal-complaint',
        hidden: false,
      },
    ],
  },
]

if (userProfile) {
  const isLevelTwo = userProfile?.hierarchical_profile === 'nivel_2'
  const isLevelThree = userProfile?.hierarchical_profile === 'nivel_3'
  const isLevelFour = userProfile?.hierarchical_profile === 'nivel_4'
  const profileName = userProfile.profile.profile
  const regexRelationshipCenter = /central\s+de\s+relacionamento/i
  const regexHumanResources = /recursos\s+humanos/i
  const regexAdministrator = /administrador/i
  const isHumanResources = regexHumanResources.test(profileName)
  const isRelationshipCenter = regexRelationshipCenter.test(profileName)
  const isAdministrator = regexAdministrator.test(profileName)

  if (isLevelTwo || isLevelThree) {
    Sgq[1]?.children.push(
      {
        title: 'Usuários por RIC',
        to: 'sgq-user-by-ric',
        hidden: false,
      },
      {
        title: 'Tipos de reclamação',
        to: 'sgq-control-panel',
        hidden: false,
      },
    )

    Sgq.splice(2, 0, {
      title: 'SAC`s',
      icon: mdiAccountCircle,
      children: [
        {
          title: 'Registrar',
          to: 'sgq-sac',
          hidden: false,
        },
      ],
    })

    Sgq.push(
      {
        title: 'GESTÃO DE PESSOAS',
        icon: mdiAccountGroupOutline,
        children: [
          {
            title: 'Requisitar',
            to: 'person-request',
            hidden: false,
          },
          {
            title: 'Desligar',
            to: 'employee-offboarding',
            hidden: false,
          },

        ],
      },
    )
  }

  // NOTE - the module was removed from the project
  // if ((isRelationshipCenter && isLevelTwo) || isLevelThree) {
  //   Sgq.push({
  //     title: 'DOCUMENTOS',
  //     icon: mdiAccessPointCheck,
  //     children: [
  //       {
  //         title: 'Processos',
  //         to: 'sgq-processes',
  //         hidden: false,
  //       },
  //       {
  //         title: 'Tipos',
  //         to: 'sgq-types',
  //         hidden: false,
  //       },
  //       {
  //         title: 'Documentos',
  //         to: 'sgq-documents',
  //         hidden: false,
  //       },
  //       {
  //         title: 'Arquivos',
  //         to: 'sgq-files',
  //         hidden: false,
  //       },
  //       {
  //         title: 'Função/Documentos',
  //         to: 'sgq-docs-on-occupation',
  //         hidden: false,
  //       },
  //     ],
  //   })
  // }

  if (((isHumanResources || isRelationshipCenter || isAdministrator) && isLevelTwo) || isLevelThree) {
    Sgq.push({
      title: 'DOCUMENTOS',
      icon: sgqDocumentIcon,
      children: [
        {
          title: 'Painel',
          to: 'sgq-panel',
          hidden: false,
        },
        {
          title: 'Ferramentas',
          to: 'sgq-tools',
          hidden: false,
        },
      ],
    })
  }

  if (isLevelThree || isLevelFour) {
    Sgq[3]?.children.unshift({
      title: 'Dashboard',
      to: 'sgq-internal-complaint-dashboard',
      hidden: false,
    })

    Sgq.push({
      title: 'RELATÓRIOS',
      icon: mdiFileDocument,
      children: [
        {
          title: 'Canal do diretor',
          to: 'sgq-reports-director-channel',
          hidden: false,
        },
      ],
    })
  }
}

export default Sgq
