const sigmaSupervisor = {
  path: '/nexus',
  component: () => import('@/views/pages/sigma/sigmaHome.vue'),
  children: [
    {
      path: 'supervisor/dashboard',
      name: 'sigma-dashboard',
      component: () => import('@/views/pages/sigma/client/clientHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/clientes',
      name: 'sigma-client',
      component: () => import('@/views/pages/sigma/client/clientHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/contratos',
      name: 'sigma-contract',
      component: () => import('@/views/pages/sigma/contract/contractHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/integracao',
      name: 'sigma-integration',
      component: () => import('@/views/pages/sigma/integration/IntegrationHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/termo_lgpd_valida',
      name: 'sigma-lgpd-validate',
      component: () => import('@/views/pages/sigma/adm-cnh/lgpd/LgpdValidate.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/termo_lgpd_consulta',
      name: 'sigma-lgpd-consult',
      component: () => import('@/views/pages/sigma/adm-cnh/lgpd/LgpdConsult.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/termo_lgpd_inutiliza',
      name: 'sigma-lgpd-disable',
      component: () => import('@/views/pages/sigma/adm-cnh/lgpd/LgpdDisable.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/retencao',
      name: 'sigma-retention',
      component: () => import('@/views/pages/sigma/retention/retentionHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/assembleia',
      name: 'sigma-assembly',
      component: () => import('@/views/pages/sigma/after-sales/assembly/AssemblyHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/encerramento',
      name: 'sigma-closing-group',
      component: () => import('@/views/pages/sigma/after-sales/closing-group/ClosingGroupHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/grupo-reserva',
      name: 'sigma-reserve-group',
      component: () => import('@/views/pages/sigma/after-sales/reserve-group/ReserveGroupHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/birthday-person',
      name: 'sigma-birthday-person',
      component: () => import('@/views/pages/sigma/after-sales/birthday-person/BirthdayPersonHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/dashboard',
      name: 'sigma-vendor',
      component: () => import('@/views/pages/sigma/vendor/VendorHome.vue'),
      meta: { layout: 'content' },
    },

    {
      path: 'supervisor/dash',
      name: 'sigma-supervisor',
      component: () => import('@/views/pages/sigma/vendor/SupervisorHome.vue'),
      meta: { layout: 'content' },
    },

    {
      path: 'supervisor/lottery',
      name: 'sigma-lottery',
      component: () => import('@/views/pages/sigma/after-sales/lottery/LotteryHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/commission-standard',
      name: 'sigma-commission-standard',
      component: () => import('@/views/pages/sigma/commission/commissionStandard.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/commission-cnh',
      name: 'sigma-commission-cnh',
      component: () => import('@/views/pages/sigma/manager/commission/CommissionCnh.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/annual-follow-up',
      name: 'sigma-annual-follow-up',
      component: () => import('@/views/pages/sigma/manager/annual-follow-up/AnnualFollowUp.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/bonus',
      name: 'sigma-bonus-standard',
      component: () => import('@/views/pages/sigma/bonus/bonusStandard.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/models',
      name: 'sigma-model',
      component: () => import('@/views/pages/sigma/models/modelsStandards.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/commission-config',
      name: 'sigma-commission-config',
      component: () => import('@/views/pages/sigma/commission-config/commissionConfig.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/bonification',
      name: 'sigma-bonification',
      component: () => import('@/views/pages/sigma/bonification/Bonification.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/delivery-adjustment',
      name: 'sigma-delivery-adjustment',
      component: () => import('@/views/pages/sigma/manager/delivery-adjustment/DeliveryAdjustment.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/sale-quality-monthly',
      name: 'sigma-sale-quality-monthly',
      component: () => import('@/views/pages/sigma/manager/sale-quality/SaleQualityMonthly.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/sale-quality-vendor',
      name: 'sigma-sale-quality-vendor',
      component: () => import('@/views/pages/sigma/manager/sale-quality/SaleQualityVendor.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/sale-quality-region',
      name: 'sigma-sale-quality-region',
      component: () => import('@/views/pages/sigma/manager/sale-quality/SaleQualityRegion.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/sale-quality-region-monthly',
      name: 'sigma-sale-quality-region-monthly',
      component: () => import('@/views/pages/sigma/manager/sale-quality/SaleQualityRegionMonthly.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/retention/vendor',
      name: 'sigma-adm-retention',
      component: () => import('@/views/pages/administrator/retention/RetentionHome.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/retention/month',
      name: 'sigma-adm-retention-month',
      component: () => import('@/views/pages/administrator/retention/RetentionHomeMonth.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/retention/region',
      name: 'sigma-adm-retention-region',
      component: () => import('@/views/pages/administrator/retention/RetentionHomeRegion.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/retention/region/month',
      name: 'sigma-adm-retention-region-month',
      component: () => import('@/views/pages/administrator/retention/RetentionHomeRegionMonth.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/sales/Yearly',
      name: 'sigma-adm-sales-yearly',
      component: () => import('@/views/pages/administrator/sales-yearly/SalesYearly.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/profitability/monthly',
      name: 'sigma-sale-profitability-monthly',
      component: () => import('@/views/pages/administrator/profitability/ProfitabilityMonthly.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/profitability/document',
      name: 'sigma-export-profitability-cpf',
      component: () => import('@/views/pages/administrator/profitability/ProfitabilityDocument.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/export/bonus',
      name: 'sigma-export-bonus',
      component: () => import('@/views/pages/sigma/manager/exportData/exportDataBonus.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/params/goal',
      name: 'sigma-params-goal',
      component: () => import('@/views/pages/administrator/registration-target/RegisterTarget.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/sales/unallocated',
      name: 'nexus-sales-unallocated',
      component: () => import('@/views/pages/administrator/sales-unallocated/salesUnallocated.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/sales/transfer',
      name: 'nexus-sales-transfer',
      component: () => import('@/views/pages/administrator/transfer-sales/TransferSales.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/utils/reserved-contracts',
      name: 'reserved-contracts',
      component: () => import('@/views/pages/administrator/reserved-contracts/Home.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/params/commissions',
      name: 'params-commissions',
      component: () => import('@/views/pages/administrator/params/Commissions.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'supervisor/params/bonifications',
      name: 'params-bonification',
      component: () => import('@/views/pages/administrator/params/Bonification.vue'),
      meta: { layout: 'content' },
    },
  ],
}

export default sigmaSupervisor
