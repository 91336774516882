const integrator = {
  path: '/integrator',
  component: () => import('@/views/pages/integrator/IntegratorHome.vue'),
  meta: { layout: 'content' },
  children: [
    {
      path: 'dashboard',
      name: 'integrator-dashboard',
      component: () => import('@/views/pages/integrator/dashboard.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'commissions-cnh',
      name: 'commissions-home',
      component: () => import('@/views/pages/integrator/commissionsCNH/Home.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'bonifications',
      name: 'list-commission-quote',
      component: () => import('@/views/pages/integrator/cotas-sales/CommissionQuote.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'delivery',
      name: 'list-bonifications',
      component: () => import('@/views/pages/integrator/bonifications/BonificationsList.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'inconsistencies',
      name: 'list-inconsistencies',
      component: () => import('@/views/pages/integrator/inconsistencies/Inconsistencies.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'perfil',
      name: 'settings-profile',
      component: () => import('@/views/pages/profiles/ProfileSettings.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'commissions',
      name: 'integrator-commissions',
      component: () => import('@/views/pages/integrator/commissions/Home.vue'),
      meta: { layout: 'content' },
    },
    {
      path: 'growth',
      name: 'sigma-params-growth',
      component: () => import('@/views/pages/integrator/Growth/GrowthBonus.vue'),
      meta: { layout: 'content' },
    },
  ],
}

export default integrator
