// axios
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================

  baseURL: process.env.VUE_APP_AXIOS_URL,

  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(new Error(error)),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    console.log('axios: ', error.response.data.data.message)
    console.log('axios: ', error.message)

    if (error?.response && error?.response?.status === 401) {
      if (router.currentRoute.path !== '/login') {
        router.push('/login')
      }
    }

    return Promise.reject(new Error(error.response.data.data.message ?? error.message))
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
